@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-sans;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-serif;
    @apply font-bold;
  }

  hr {
    @apply border-gray-100 dark:border-gray-600;
  }
  * {
    @apply dark:text-white;
  }
}

@layer components {
  .stack {
    @apply space-y-2;
  }
  .hstack {
    @apply flex flex-row items-center space-x-2;
  }
  .vstack {
    @apply flex flex-col items-center space-y-2;
  }
  .center {
    @apply flex items-center justify-center;
  }
}
